export const Booksy = () => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8555 5.79589V4.9066C14.8555 3.77841 14.8555 2.63694 14.8555 1.50874C14.8555 0.63273 14.7287 0.513273 13.9172 0.5C13.6763 0.5 13.4353 0.5 13.2071 0.5C12.4336 0.513273 12.3829 0.553091 12.3829 1.32292C12.3829 3.02185 12.3829 4.73406 12.3829 6.43299C12.3829 8.13192 12.3829 9.84413 12.3829 11.5431C12.3829 11.8351 12.3322 12.1536 12.5985 12.366C13.1183 12.7907 13.6763 13.1756 14.2595 13.5074C14.5765 13.68 14.8048 13.534 14.8428 13.1225C14.8682 12.7774 14.8809 12.4323 14.8555 12.0872C14.8175 11.2643 15.0711 10.5343 15.4895 9.8574C16.9477 7.48155 20.0797 7.37537 21.6774 9.61849C22.5269 10.813 22.6664 12.1536 22.4635 13.5605C22.3114 14.6091 21.7661 15.432 20.9419 16.0426C19.5344 17.1044 17.9748 17.1575 16.3898 16.6929C14.8809 16.2549 13.5875 15.3657 12.3322 14.4366C11.4065 13.7464 10.5189 13.0163 9.5933 12.3129C8.03366 11.1183 6.28382 10.7201 4.40718 11.1714C2.37837 11.6625 0.932849 12.9234 0.108648 14.9144C-0.132272 15.5117 0.0198876 15.7373 0.641209 15.7506C1.16109 15.7638 1.68097 15.7373 2.21353 15.7506C2.56857 15.7638 2.80949 15.6311 2.94897 15.286C2.99969 15.1798 3.05041 15.0604 3.11381 14.9675C3.77317 13.7995 5.10458 13.242 6.3599 13.6136C6.9305 13.7729 7.47574 14.0251 7.98294 14.3569C9.12414 15.1135 10.2146 15.9497 11.3051 16.7859C13.4987 18.4981 15.8446 19.7192 18.7102 19.467C20.663 19.2944 22.286 18.5511 23.5413 16.9982C24.543 15.7638 24.9868 14.3171 24.9995 12.6978C25.0122 11.2511 24.7713 9.91049 24.0612 8.66284C22.8566 6.57899 21.0307 5.47734 18.7356 5.38443C17.6324 5.35788 16.5546 5.64989 15.6163 6.24717C14.8555 6.73827 14.8428 6.72499 14.8555 5.79589Z"
        fill="#001965"
      />
    </svg>
  );
};
